/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../node_modules/primeflex/primeflex";
@import "../node_modules/primeicons/primeicons.css";
@import "./assets/layout/css/layout-light.min.css";
@import "./assets/theme/theme-blue.min.css";

@each $key, $val in $breakpoints {
  @media screen and (min-width: #{$val}) {
    .#{$key + $separator} {
      @each $col,$width in $grid-columns {
        &#{$col} {
          flex: 0 0 auto;
          padding: $gutter;
          width: #{$width};
        }
      }


      &col-fixed {
        flex: 0 0 auto;
        padding: $gutter;
      }

      &col {
        flex-grow: 1;
        flex-basis: 0;
        padding: $gutter;
      }
    }
  }
}


.p-datatable-header .p-breadcrumb {
  background: none;
  border: none;

  padding: 0.5rem;
}

.p-breadcrumb .p-menuitem-text {
  cursor: pointer;
}

#topbar-left {
  white-space: nowrap;
}
